
<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
              <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
              <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
        <v-row class="ma-2">
            <v-col cols="12" sm="3" md="3">
                <v-select v-model="selected_ay" label="Academic Year" :items="ay_list" item-text="ay" item-value="id"
                    outlined></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
                <v-select v-model="selected_org" label="Organization" :items="org_list" item-text="name" item-value="id"
                    outlined></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
                <v-btn class="primary mt-2" @click="fetchCurrentDashboardData"> Fetch </v-btn>
            </v-col>
        </v-row>
        <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
        <div v-else>
            <div class="ma-3" v-if="Array.isArray(CompanyOfferingData) &&
                CompanyOfferingData.length">

                <fieldset style="border-color: white;" class="pa-3">
                    <legend> Company Offering Distribution </legend>
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <center>
                                <pie :data="CompanyOfferingBarData" :key="rerender" :options="chartOptions" />
                            </center>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-row justify="center" v-for="(item1, index1) in CompanyOfferingData" :key="index1">
                                <v-col cols="12" sm="8" md="8">
                                    {{ item1[0].name }}
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    {{ item1[1] }}
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>

                </fieldset>
            </div>

            <div class="ma-3" v-if="Array.isArray(program_wise_apply_count_list) &&
                program_wise_apply_count_list.length">

                <fieldset style="border-color: white;" class="pa-3">
                    <legend> Company Placement </legend>
                    <v-row>
                        <v-col cols="12" sm="5" md="5">
                            <center>
                                <barchart :data="program_bar_data" :key="rerender1" :options="chartOptions" />
                            </center>
                        </v-col>
                        <v-col cols="12" sm="7" md="7">
                            <v-simple-table id="exceltable">
                                <tbody>
                                    <tr>
                                        <th>Program</th>
                                        <th>Applied Student</th>
                                        <th>Placed Student</th>
                                        <th>Un Placed Studnet</th>
                                    </tr>
                                    <tr v-for="(item, index1) in program_wise_count_list" :key="index1">

                                        <td> {{ item[0].name }} </td>
                                        <td class="cardhover"> {{ program_wise_apply_count_list[index1][1] }}</td>
                                        <td class="cardhover"> {{ item[1] }} </td>
                                        <td class="cardhover"> {{ program_wise_unplaced_count_list[index1][1] }}</td>
                                    </tr>

                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </fieldset>
            </div>


            <div class="ma-3" v-if="Array.isArray(program_wise_apply_count_list_internship) &&
                program_wise_apply_count_list_internship.length">

                <fieldset style="border-color: white;" class="pa-3">
                    <legend> Internship </legend>
                    <v-row>
                        <v-col cols="12" sm="5" md="5">
                            <center>
                                <barchart :data="program_bar_data_internship" :key="rerender1" :options="chartOptions" />
                            </center>
                        </v-col>
                        <v-col cols="12" sm="7" md="7">
                            <v-simple-table id="exceltable">
                                <tbody>
                                    <tr>
                                        <th>Program</th>
                                        <th>Applied Student</th>
                                        <th>Placed Student</th>
                                        <th>Un Placed Studnet</th>
                                    </tr>
                                    <tr v-for="(item, index1) in program_wise_count_list_internship" :key="index1">

                                        <td> {{ item[0].name }} </td>
                                        <td class="cardhover"> {{ program_wise_apply_count_list_internship[index1][1] }}</td>
                                        <td class="cardhover"> {{ item[1] }} </td>
                                        <td class="cardhover"> {{ program_wise_unplaced_count_list_internship[index1][1] }}</td>
                                    </tr>

                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </fieldset>
            </div>

        </div>
    </div>
</template>
<script>
import pie from '../TPO/chart/PieChart.vue'
import barchart from '../TPO/chart/bar_chart.vue'
import axios from "axios";
export default {
    components: { pie, barchart },
    data: () => ({
        selected_ay: "",
        loading: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        selected_org: "",
        rerender: 0,
        rerender1: 0,
        rerender2: 0,

        CompanyOfferingData: [],
        CompanyOfferingBarData: [],


        program_wise_apply_count_list: [],
        program_wise_count_list: [],
        program_wise_unplaced_count_list: [],
        program_bar_data: [],

        program_wise_apply_count_list_internship: [],
        program_wise_count_list_internship: [],
        program_wise_unplaced_count_list_internship: [],
        program_bar_data_internship: [],

        chartOptions: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },
                }]
            },
            responsive: true,
        },
    }),
    props: ['ay_list', 'current_ay', 'org_list', 'current_org'],
    mounted() {

        this.selected_ay = this.current_ay;
        this.selected_org = this.current_org.id;

    },
    methods: {
        fetchCurrentDashboardData() {
            this.loading = true;
            var params = {
                selected_ay: this.selected_ay,
                selected_org: this.selected_org
            };
            this.CompanyOfferingBarData = [];
            this.CompanyOfferingData = [];
            this.program_bar_data = "";

            this.program_wise_count_list = [];
            this.program_wise_apply_count_list = [];
            this.program_wise_unplaced_count_list = [];
            
            this.program_wise_count_internship = [];
            this.program_wise_apply_count_internship = [];
            this.program_wise_unplaced_count_internship = [];
            axios.post("/Dashboard/fetchCurrentDashboardData", params).then((res) => {
                if (res.data.msg == "200") {
                    this.rerender++;
                    this.CompanyOfferingData = res.data.CompanyOfferingData;

                    this.program_wise_count_list = res.data.program_wise_count_list;
                    this.program_wise_apply_count_list = res.data.program_wise_apply_count_list;
                    this.program_wise_unplaced_count_list = res.data.program_wise_unplaced_count_list;

                    this.program_wise_count_list_internship = res.data.program_wise_count_list_internship;
                    this.program_wise_apply_count_list_internship = res.data.program_wise_apply_count_list_internship;
                    this.program_wise_unplaced_count_list_internship = res.data.program_wise_unplaced_count_list_internship;
                    var label_array = [];
                    var data_array = [];

                    for (var x in this.CompanyOfferingData) {
                        label_array.push(this.CompanyOfferingData[x][0].name)
                        data_array.push(this.CompanyOfferingData[x][1])
                    }
                    this.CompanyOfferingBarData = {
                        "labels": label_array,
                        "datasets": [
                            {
                                "backgroundColor": ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#FFFFCC', '#BFFF00', '#FCA3FF', '#FFA3D9', '#00FFD1', '#834848'],
                                "data": data_array,
                            }
                        ]
                    };


                    // verifiy Data


                    if (this.program_wise_apply_count_list != 'undefined' || this.program_wise_apply_count_list.length > 0) {
                        for (var i in this.program_wise_apply_count_list) {
                            var flag = false;
                            var flag1 = false;
                            for (var j in this.program_wise_count_list) {
                                if (this.program_wise_apply_count_list[i][0].id == this.program_wise_count_list[j][0].id) {
                                    flag = true;
                                }
                            }
                            if (flag == false) {
                                var map = { "abbrivation": this.program_wise_apply_count_list[i][0].abbrivation, "name": this.program_wise_apply_count_list[i][0].name, "id": this.program_wise_apply_count_list[i][0].id };
                                var array = []
                                array.push(map);
                                array.push(0);
                                this.program_wise_count_list.push(array);
                            }

                            for (var j in this.program_wise_unplaced_count_list) {
                                if (this.program_wise_apply_count_list[i][0].id == this.program_wise_unplaced_count_list[j][0].id) {
                                    flag1 = true;
                                }
                            }
                            if (flag1 == false) {
                                var map = { "abbrivation": this.program_wise_apply_count_list[i][0].abbrivation, "name": this.program_wise_apply_count_list[i][0].name, "id": this.program_wise_apply_count_list[i][0].id };
                                var array = []
                                array.push(map);
                                array.push(0);
                                this.program_wise_unplaced_count_list.push(array);
                            }

                        }
                    }


                    if (this.program_wise_apply_count_list_internship != 'undefined' || this.program_wise_apply_count_list_internship.length > 0) {
                        for (var i in this.program_wise_apply_count_list_internship) {
                            var flag = false;
                            var flag1 = false;
                            for (var j in this.program_wise_count_list_internship) {
                                if (this.program_wise_apply_count_list_internship[i][0].id == this.program_wise_count_list_internship[j][0].id) {
                                    flag = true;
                                }
                            }
                            if (flag == false) {
                                var map = { "abbrivation": this.program_wise_apply_count_list_internship[i][0].abbrivation, "name": this.program_wise_apply_count_list_internship[i][0].name, "id": this.program_wise_apply_count_list_internship[i][0].id };
                                var array = []
                                array.push(map);
                                array.push(0);
                                this.program_wise_count_list_internship.push(array);
                            }

                            for (var j in this.program_wise_unplaced_count_list_internship) {
                                if (this.program_wise_apply_count_list_internship[i][0].id == this.program_wise_unplaced_count_list_internship[j][0].id) {
                                    flag1 = true;
                                }
                            }
                            if (flag1 == false) {
                                var map = { "abbrivation": this.program_wise_apply_count_list_internship[i][0].abbrivation, "name": this.program_wise_apply_count_list_internship[i][0].name, "id": this.program_wise_apply_count_list_internship[i][0].id };
                                var array = []
                                array.push(map);
                                array.push(0);
                                this.program_wise_unplaced_count_list_internship.push(array);
                            }

                        }
                    }


                    var program_wise_count_list_array = [];
                    var program_wise_unplaced_count_list_array = [];
                    var program_wise_apply_count_list_array = [];
                    for (var x in this.program_wise_count_list) {
                        for (var y in this.program_wise_unplaced_count_list) {
                            for (var z in this.program_wise_apply_count_list) {
                                if (this.program_wise_count_list[x][0].id == this.program_wise_unplaced_count_list[y][0].id && this.program_wise_count_list[x][0].id == this.program_wise_apply_count_list[z][0].id) {
                                    program_wise_count_list_array.push(this.program_wise_count_list[x]);
                                    program_wise_unplaced_count_list_array.push(this.program_wise_unplaced_count_list[y]);
                                    program_wise_apply_count_list_array.push(this.program_wise_apply_count_list[z]);
                                }
                            }
                        }
                    }
                    this.program_wise_count_list = program_wise_count_list_array;
                    this.program_wise_unplaced_count_list = program_wise_unplaced_count_list_array;
                    this.program_wise_apply_count_list = program_wise_apply_count_list_array;

                    var program_wise_count_list_array_internship = [];
                    var program_wise_unplaced_count_list_array_internship = [];
                    var program_wise_apply_count_list_array_internship = [];
                    for (var x in this.program_wise_count_list_internship) {
                        for (var y in this.program_wise_unplaced_count_list_internship) {
                            for (var z in this.program_wise_apply_count_list_internship) {
                                if (this.program_wise_count_list_internship[x][0].id == this.program_wise_unplaced_count_list_internship[y][0].id && this.program_wise_count_list_internship[x][0].id == this.program_wise_apply_count_list_internship[z][0].id) {
                                    program_wise_count_list_array_internship.push(this.program_wise_count_list_internship[x]);
                                    program_wise_unplaced_count_list_array_internship.push(this.program_wise_unplaced_count_list_internship[y]);
                                    program_wise_apply_count_list_array_internship.push(this.program_wise_apply_count_list_internship[z]);
                                }
                            }
                        }
                    }
                    this.program_wise_count_list_internship = program_wise_count_list_array_internship;
                    this.program_wise_unplaced_count_list_internship = program_wise_unplaced_count_list_array_internship;
                    this.program_wise_apply_count_list_internship = program_wise_apply_count_list_array_internship;

                    if ((this.program_wise_count_list != 'undefined' || this.program_wise_count_list.length > 0) && (this.program_wise_unplaced_count_list != 'undefined' || this.program_wise_unplaced_count_list.length > 0) && (this.program_wise_apply_count_list != 'undefined' || this.program_wise_apply_count_list.length > 0)) {
                        var label = [];
                        var placed_data = [];
                        var unplaced_data = [];
                        var apply_data = [];
                        var total = 0
                        var total_unplaced = 0
                        var total_apply = 0
                        for (var x in this.program_wise_count_list) {
                            for (var y in this.program_wise_unplaced_count_list) {
                                for (var z in this.program_wise_apply_count_list) {
                                    if (this.program_wise_count_list[x][0].id == this.program_wise_unplaced_count_list[y][0].id && this.program_wise_count_list[x][0].id == this.program_wise_apply_count_list[z][0].id) {
                                        label.push(this.program_wise_count_list[x][0].abbrivation);
                                        placed_data.push(this.program_wise_count_list[x][1]);
                                        unplaced_data.push(this.program_wise_unplaced_count_list[y][1]);
                                        apply_data.push(this.program_wise_apply_count_list[z][1]);
                                    }

                                    if (x == 0 && y == 0)
                                        total_apply += this.program_wise_apply_count_list[z][1];
                                }
                                if (x == 0)
                                    total_unplaced += this.program_wise_unplaced_count_list[y][1];
                            }
                            total += this.program_wise_count_list[x][1];
                        }
                        var map = { "name": "TOTAL", "id": "TOTAL" };
                        var array = []
                        array.push(map);
                        array.push(total);
                        this.program_wise_count_list.push(array);


                        array = []
                        array.push(map);
                        array.push(total_unplaced);
                        this.program_wise_unplaced_count_list.push(array);

                        array = []
                        array.push(map);
                        array.push(total_apply);
                        this.program_wise_apply_count_list.push(array);
                        this.program_bar_data = { "labels": label, "datasets": [{ "data": apply_data, "label": "Applied", "backgroundColor": 'skyblue', }, { "data": unplaced_data, "label": "UnPlaced", "backgroundColor": 'red', }, { "data": placed_data, "label": "Placed", "backgroundColor": 'green', }] };
                    }
                    

                    if ((this.program_wise_count_list_internship != 'undefined' || this.program_wise_count_list_internship.length > 0) && (this.program_wise_unplaced_count_list_internship != 'undefined' || this.program_wise_unplaced_count_list_internship.length > 0) && (this.program_wise_apply_count_list_internship != 'undefined' || this.program_wise_apply_count_list_internship.length > 0)) {
                        var label = [];
                        var placed_data = [];
                        var unplaced_data = [];
                        var apply_data = [];
                        var total = 0
                        var total_unplaced = 0
                        var total_apply = 0
                        for (var x in this.program_wise_count_list_internship) {
                            for (var y in this.program_wise_unplaced_count_list_internship) {
                                for (var z in this.program_wise_apply_count_list_internship) {
                                    if (this.program_wise_count_list_internship[x][0].id == this.program_wise_unplaced_count_list_internship[y][0].id && this.program_wise_count_list_internship[x][0].id == this.program_wise_apply_count_list_internship[z][0].id) {
                                        label.push(this.program_wise_count_list_internship[x][0].abbrivation);
                                        placed_data.push(this.program_wise_count_list_internship[x][1]);
                                        unplaced_data.push(this.program_wise_unplaced_count_list_internship[y][1]);
                                        apply_data.push(this.program_wise_apply_count_list_internship[z][1]);
                                    }

                                    if (x == 0 && y == 0)
                                        total_apply += this.program_wise_apply_count_list_internship[z][1];
                                }
                                if (x == 0)
                                    total_unplaced += this.program_wise_unplaced_count_list_internship[y][1];
                            }
                            total += this.program_wise_count_list_internship[x][1];
                        }
                        var map = { "name": "TOTAL", "id": "TOTAL" };
                        var array = []
                        array.push(map);
                        array.push(total);
                        this.program_wise_count_list_internship.push(array);


                        array = []
                        array.push(map);
                        array.push(total_unplaced);
                        this.program_wise_unplaced_count_list_internship.push(array);

                        array = []
                        array.push(map);
                        array.push(total_apply);
                        this.program_wise_apply_count_list_internship.push(array);
                        this.program_bar_data_internship = { "labels": label, "datasets": [{ "data": apply_data, "label": "Applied", "backgroundColor": 'skyblue', }, { "data": unplaced_data, "label": "UnPlaced", "backgroundColor": 'red', }, { "data": placed_data, "label": "Placed", "backgroundColor": 'green', }] };
                    }


                    this.loading = false;
                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.loading = false;
                }
            });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};
</script>
<style scoped>
legend {
    background-color: gray;
    color: white;
    padding: 5px 10px;
}
</style>
